import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, ReplaySubject, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const USE_NETWORKS_URL = new InjectionToken<string>('USE_NETWORKS_URL');

export interface INetworkJson {
  type: string;
  mcc: string;
  mnc: string;
  countryCode: string;
  countryName: string;
  brand: string;
  operator: string;
}

export interface INetworkCountry {
  countryCode: string;
  countryName: string;
}

export interface INetworkMccMnc {
  mccMnc: string;
  brand: string;
  operator: string;
}

export interface INetworkCall {
  mcc: string;
  mnc: string;
  operator: string;
  brand: string;
  countryName: string;
  countryCode?: string;
  lat?: string;
  long?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SimsService {
  private networks$: ReplaySubject<Array<INetworkJson>>;
  private networks: Array<INetworkJson>;

  private networksCache = {
    countries: null,
    mccsByCountryCode: {},
    networksByCountryCode: {},
    networkByMccMnc: {},
    countryByMcc: {},
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(USE_NETWORKS_URL)
    protected useNetworksUrl: string = 'https://raw.githubusercontent.com/iotconnectivity/mcc-mnc-list/iotconnectivity/mcc-mnc-list.json'
  ) {
    this.getNetworksJson().subscribe();
  }

  public getNetworksJson(): Observable<Array<INetworkJson>> {
    if (!this.networks$) {
      this.networks$ = new ReplaySubject<Array<INetworkJson>>();
      this.httpClient
        .get<INetworkJson[]>(this.useNetworksUrl)
        .pipe(
          catchError(err => {
            console.error(err);
            console.info('Using static networks file');

            return from(
              import('mcc-mnc-list/mcc-mnc-list.json').then(
                (result: Record<number, INetworkJson>) => Object.values(result)
              )
            );
          })
        )
        .subscribe(list => {
          this.networks = list.filter(n => {
            return (
              (n.type === 'National' || n.type === 'International') &&
              (!!n.brand || !!n.operator) &&
              !!n.countryCode &&
              !!n.mcc &&
              /^[0-9]+$/.test(n.mcc)
            );
          });
          this.networks$.next(this.networks);
          return this.networks;
        });
    }
    return this.networks$;
  }

  public getCountries(): Array<INetworkCountry> {
    if (this.networks && !this.networksCache.countries) {
      const finalCountries = [];
      const aux = {};
      this.networks.forEach(n => {
        if (!aux[n.countryCode]) {
          aux[n.countryCode] = true;
          finalCountries.push({
            countryCode: n.countryCode,
            countryName: n.countryName,
          });
        }
      });
      this.networksCache.countries = finalCountries;
    }
    return this.networksCache.countries;
  }

  public getCountryMccsByCountryCode(countryCode: string): Array<string> {
    if (this.networks && !this.networksCache.mccsByCountryCode[countryCode]) {
      const mccs = {};
      this.networks.forEach(n => {
        if (n.countryCode === countryCode) {
          mccs[n.mcc] = true;
        }
      });
      this.networksCache.mccsByCountryCode[countryCode] = Object.keys(mccs);
    }
    return this.networksCache.mccsByCountryCode[countryCode] || [];
  }

  public getNetworkCallByMccMnc(mcc: string, mnc: string): INetworkCall {
    if (this.networks && !this.networksCache.networkByMccMnc[mcc + '-' + mnc]) {
      let result: any = this.networks.find(
        n =>
          parseInt(n.mcc, 10) === parseInt(mcc, 10) &&
          parseInt(n.mnc, 10) === parseInt(mnc, 10)
      );

      if (!result && parseInt(mnc, 10) === 0) {
        const firstByCountry = this.getCountryByMcc(mcc);
        if (firstByCountry) {
          result = {
            countryName: firstByCountry.countryName,
            countryCode: firstByCountry.countryCode,
            lat: firstByCountry.lat,
            long: firstByCountry.long,
          };
        }
      }

      this.networksCache.networkByMccMnc[mcc + '-' + mnc] = result;
    }
    return this.networksCache.networkByMccMnc[mcc + '-' + mnc];
  }

  public getCountryByMcc(mcc: string): INetworkCall {
    if (this.networks && !this.networksCache.countryByMcc[mcc]) {
      this.networksCache.countryByMcc[mcc] = this.networks.find(n => {
        return parseInt(n.mcc, 10) === parseInt(mcc, 10);
      });
    }
    return this.networksCache.countryByMcc[mcc];
  }

  public getNetworkMccMncsByCountryCode(
    countryCode: string
  ): Array<INetworkMccMnc> {
    if (
      this.networks &&
      !this.networksCache.networksByCountryCode[countryCode]
    ) {
      const finalNetworks = [];
      const aux = {};
      this.networks.forEach(n => {
        if (n.countryCode === countryCode && n.brand) {
          if (!aux[n.brand]) {
            aux[n.brand] = {
              brand: n.brand,
              mccMnc: [],
              operator: n.operator,
            };
            finalNetworks.push(aux[n.brand || n.operator]);
          }
          aux[n.brand || n.operator].mccMnc.push(n.mcc + '-' + n.mnc);
        }
      });
      finalNetworks.forEach(function (network) {
        network.mccMnc = network.mccMnc.join(',');
      });
      this.networksCache.networksByCountryCode[countryCode] = finalNetworks;
    }
    return this.networksCache.networksByCountryCode[countryCode] || [];
  }

  public locationFilterDecoder(
    value: string
  ): Observable<{ mcc: INetworkCountry; mnc: INetworkMccMnc }> {
    return this.getNetworksJson().pipe(
      map(() => {
        // If includes - is a mcc-mnc and use just the first comma separated
        if (value.includes('-')) {
          const firstMccMnc = value.split(',').shift().split('-');
          const networkJson = this.networks.find(
            n => n.mcc === firstMccMnc[0] && n.mnc == firstMccMnc[1]
          );
          if (networkJson) {
            const networkMccMnc = this.getNetworkMccMncsByCountryCode(
              networkJson.countryCode
            ).find(n => n.brand === networkJson.brand);
            return {
              mcc: {
                countryCode: networkJson.countryCode,
                countryName: networkJson.countryName,
              },
              mnc: networkMccMnc || null,
            };
          }
        } else {
          // If not, search the first country with the same mccMnc array
          const firstMcc = value.split(',').shift();
          const networkJson = this.networks.find(
            n =>
              n.mcc === firstMcc &&
              this.getCountryMccsByCountryCode(n.countryCode).join(',') ===
                value
          );
          if (networkJson) {
            return {
              mcc: {
                countryCode: networkJson.countryCode,
                countryName: networkJson.countryName,
              },
              mnc: null,
            };
          }
        }
      })
    );
  }
}
