import { NgModule } from '@angular/core';

import { CheckboxComponent } from './checkbox.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    CheckboxComponent
  ],
  declarations: [
    CheckboxComponent
  ],
  providers: [],
  entryComponents: [
    CheckboxComponent
  ]
})
export class CheckboxModule { }
