import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment-timezone';
const moment = moment_;
import { MomentInput } from 'moment-timezone';
import { SessionService } from '../../services/session.service';

@Pipe({
  name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
  constructor(private session: SessionService) {}

  transform(date: MomentInput, withoutSuffix = false): string {
    if (date) {
      // Add Z if not at final and remove _
      if (typeof date === 'string') {
        date = date.replace(/_/g, ' ');
        if (!date.match(/Z$/)) {
          date += 'Z';
        }
      }

      const apiTz = this.session.getTimezone();

      const m = moment(date);
      if (m.isValid()) {
        return m.fromNow(withoutSuffix);
      }
    }
    return '';
  }
}
