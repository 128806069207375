<div>

  <ng-template
    #advancedSearchContent
    let-modal
  >
    <div class="modal-header">
      <h4 class="modal-title">{{'label.advancedFilters' | translate}}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss()"
        id="table-advanced-filters-close"
      >
        <span
          aria-hidden="true"
          id="table-advanced-filters-close"
        >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content select="[advancedSearch]"></ng-content>
    </div>
    <div class="modal-footer d-flex justify-content-around">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.close(true)"
        id="table-advanced-filters-reset"
      >{{'label.reset' |
        translate}}</button>
      <button
        type="button"
        class="btn btn-tertiary"
        (click)="modal.close()"
        id="table-advanced-filters-apply-filters"
      >{{'label.applyFilters' |
        translate}}</button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.dismiss()"
        id="table-advanced-filters-cancel"
      >{{'label.cancel' | translate}}</button>
    </div>
  </ng-template>

  <div class="bg-light rounded p-2 mb-2 d-flex flex-wrap justify-content-between align-items-center">

    <div class="d-inline-flex align-items-center mr-2">
      <ng-content
        *ngIf="showSearch"
        select="[smartSearch]"
      ></ng-content>
      <div class="d-flex">
        <button
          *ngIf="showSearch && advancedSearchSubmit.observers.length > 0"
          type="button"
          class="btn btn-big-icon btn-outline-tertiary border-0"
          title="{{'label.advancedFilters' | translate}}"
          (click)="openAdvancedSearch(advancedSearchContent)"
          id="table-advanced-filters"
        >
          <i
            class="material-symbols-outlined"
            id="table-advanced-filters"
          >filter_alt</i>
          <span
            *ngIf="advancedSearchCount"
            class="badge badge-primary"
          >{{advancedSearchCount}}</span>
        </button>
        <button
          *ngIf="('customize-me' | permission) && isCustomizable"
          type="button"
          class="btn btn-big-icon btn-outline-tertiary border-0"
          title="{{'label.customizeThisTable' | translate}}"
          (click)="customize()"
          id="table-customizetable"
        >
          <i
            class="material-symbols-outlined"
            id="table-customizetable"
          >table</i>
        </button>
      </div>
    </div>

    <div class="flex-grow-1 d-inline-flex justify-content-end align-items-center">

      <div class="d-flex flex-column">
        <div
          *ngFor="let row of bulkActionRows"
          class="d-inline-flex justify-content-end align-items-center"
        >
          <div
            *ngFor="let group of row"
            class="mx-2"
          >
            <div *ngIf="group.visible">
              <ng-container *ngFor="let action of group.actions">
                <button
                  *ngIf="action.hasPermission"
                  type="button"
                  class="btn btn-big-icon btn-outline-tertiary border-0"
                  (click)="executeBulkAction(action)"
                  [disabled]="!getTotalSelected() || action.enabled && !action.enabled(selectedItems, selectedMode, searchParams)"
                  title="{{action.label | translate}}"
                  id="{{action.id}}"
                >
                  <i
                    class="material-symbols-outlined"
                    id="{{action.id}}"
                  >{{action.icon}}</i>
                  <span
                    *ngIf="action.showLabelOnButton"
                    class="ml-2 align-text-bottom"
                    id="{{action.id}}"
                  >{{action.label | translate}}</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column">
        <div
          *ngFor="let row of actionRows"
          class="d-inline-flex justify-content-end align-items-center"
        >
          <div
            *ngFor="let group of row"
            class="mx-2"
          >
            <div *ngIf="group.visible">
              <ng-container *ngFor="let action of group.actions">
                <button
                  type="button"
                  class="btn btn-big-icon btn-outline-tertiary border-0"
                  (click)="action.action()"
                  title="{{action.label | translate}}"
                  id="{{action.id}}"
                >
                  <i
                    class="material-symbols-outlined"
                    id="{{action.id}}"
                  >{{action.icon}}</i>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <ng-content select="[extraData]"></ng-content>

      <div
        *ngIf="loading"
        class="ml-2 text-center"
      >
        <pod-loading-dots size="sm"></pod-loading-dots>
      </div>
      <div
        *ngIf="!loading"
        class="ml-2"
        [closeDelay]="1500"
        [ngbTooltip]="(!maxFiltersForced && (totalItems > maxFiltersInTable)) ? tipContent : ''"
        placement="left"
      >
        <div class="d-flex justify-content-between">
          <small class="font-weight-bold">{{'label.total' | translate}}:</small>
          <small class="ml-3">{{ totalItems === 0 ? '0' : countAmount(totalItems) }}</small>
        </div>
        <div
          *ngIf="showSearch"
          class="d-flex justify-content-between"
        >
          <small class="font-weight-bold">{{'label.filtered' | translate}}:</small>
          <small class="ml-3">{{items.$totalCount === 0 ? '0' : countAmount(items.$totalCount)}}</small>
        </div>
        <div
          *ngIf="exportData || bulkActions || showSelected"
          class="d-flex justify-content-between"
        >
          <small class="font-weight-bold">{{'label.selected' | translate}}:</small>
          <small class="ml-3">{{getTotalSelected() === 0 ? '0' : countAmount(getTotalSelected())}}</small>
        </div>
      </div>

    </div>
  </div>

  <div
    class="table-responsive customizable-table"
    [ngClass]="{'customizable-table-min-height': selectionModeDropdownOpened}"
  >
    <table class="table table-hover table-ordered table-pod">
      <thead>
        <tr>
          <th
            *ngIf="(exportData || bulkActions || showSelected)"
            class="w-1"
          >
            <div
              *ngIf="!selectionModes"
              class="form-check form-check-mat-icon"
            >
              <label class="form-check-label">
                <input
                  class="form-check-input form-control"
                  type="checkbox"
                  [checked]="selectedMode === CtSelectedMode.VISIBLE"
                  (change)="switchSelectVisible()"
                />
                <span></span>
              </label>
            </div>
            <div
              *ngIf="selectionModes && items && items.length"
              class="d-inline-block"
              ngbDropdown
              (openChange)="selectionModeDropdownOpened = $event"
            >
              <button
                type="button"
                class="btn bg-transparent p-1"
                ngbDropdownToggle
              >
                <i class="material-symbols-outlined">{{!!selectedMode ? 'check_box' : 'check_box_outline_blank'}}</i>
              </button>
              <div ngbDropdownMenu>
                <button
                  type="button"
                  class="dropdown-item"
                  (click)="selectVisible()"
                  id="customizetable-selectVisible"
                >{{'label.selectVisible' |
                  translate}} ({{items.length}})</button>
                <button
                  type="button"
                  class="dropdown-item"
                  (click)="selectAll()"
                  id="customizetable-selectAll"
                >{{'label.selectAll' | translate}}
                  ({{countAmount(items.$totalCount)}})</button>
                <button
                  type="button"
                  class="dropdown-item"
                  (click)="deselectAll()"
                  id="customizetable-deselectAll"
                >{{'label.deselectAll' | translate}}</button>
              </div>
            </div>
          </th>
          <ng-container *ngFor="let key of selectedFields">
            <th
              class="text-center"
              [ngClass]="[
              fields[key].order ? 'column-ordered' : '',
              key === currentSort?.field && currentSort.order ? ('column-ordered-' + currentSort.order) : ''
            ].join(' ')"
              (click)="fields[key].order && setOrder(key)"
              [innerHTML]="fields[key].label | translate | safeHtml"
            ></th>
          </ng-container>
          <th
            *ngIf="operations?.length"
            class="w-1"
          >{{'label.operations' | translate}}</th>
        </tr>
      </thead>
      <tbody *ngIf="items?.length">
        <tr
          *ngFor="let item of items"
          [ngClass]="[(routerTarget || selectOnClick || showSelected) ? 'cursor-pointer' : '', rowClass ? rowClass(item) : '']"
          [routerLink]="(!selectOnClick && routerTarget) ? [routerTarget, (item[routerTargetField] || item.getId())] : null"
          (click)="handleRowClick($event, item)"
        >
          <td
            *ngIf="exportData || bulkActions || showSelected"
            (click)="$event.stopPropagation()"
          >
            <span class="form-check form-check-mat-icon">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input form-control"
                  [checked]="selectedMode === 'all' || !!selectedItems[item.getId()]"
                  (change)="selectItem($event, item)"
                  [disabled]="selectedMode === 'all'"
                />
                <span></span>
              </label>
            </span>
          </td>
          <ng-container *ngFor="let key of selectedFields">
            <td
              [ngClass]="[
                fields[key].classes ? fields[key].classes : '',
                fields[key].click ? 'cursor-pointer' : ''
              ]"
              (click)="fields[key].click && fields[key].click($event, item)"
            >
              <ng-template #linkTdContent>
                <a
                  [routerLink]="fields[key].link(item)"
                  [queryParams]="fields[key].queryParams ? fields[key].queryParams(item) : null"
                >
                  <span
                    [innerHTML]="fields[key].content(item) | safeHtml"
                    id="table-select-item"
                  ></span>
                </a>
              </ng-template>
              <span
                *ngIf="selectOnClick || !fields[key].link || !fields[key].link(item); else linkTdContent"
                [innerHTML]="fields[key].content(item) | safeHtml"
              ></span>
            </td>
          </ng-container>
          <td
            *ngIf="operations?.length"
            (click)="$event.stopPropagation()"
            class="text-center"
          >
            <div class="btn-group">
              <div *ngFor="let operation of operations">
                <button
                  *ngIf="operation.hasPermission && (!operation.hide || !operation.hide(item)) && (operation.hideAsync ? !(operation.hideAsync | asyncFunction:item | async) : true)"
                  type="button"
                  class="btn btn-link"
                  (click)="operation.action(item)"
                  [disabled]="operation.disabled && operation.disabled(item)"
                  [title]="operation.label | translate"
                  id={{operation.id}}
                >
                  <i
                    class="material-symbols-outlined"
                    id={{operation.id}}
                  >{{operation.icon}}</i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    *ngIf="pageInfo.size && items?.length"
    class="d-flex flex-wrap align-items-center justify-content-between mt-3"
  >

    <div class="mb-2">
      <div class="d-inline-block form-inline">
        <small>{{'label.show' | translate}}</small>
        <select
          class="custom-select custom-select-sm ml-2"
          [(ngModel)]="pageInfo.size"
          (ngModelChange)="setPageSize(pageInfo.size)"
        >
          <option
            *ngFor="let ipp of itemsPerPage"
            [value]="ipp"
          >{{ipp}}</option>
        </select>
        <small class="ml-2">{{'label.recordsPerPage' | translate}}</small>
      </div>
    </div>
    <div
      class="mb-2"
      [closeDelay]="1500"
      [ngbTooltip]="(!maxFiltersForced && (totalItems > maxFiltersInTable)) ? tipContent : ''"
      placement="top"
    >
      <ngb-pagination
        class="d-inline-block pod-pagination"
        size="sm"
        [collectionSize]="items.$totalCount"
        [pageSize]="pageInfo.size"
        [(page)]="pageInfo.page"
        [maxSize]="10"
        (pageChange)="onPageInfoChange()"
      >
      </ngb-pagination>
    </div>
  </div>

  <ng-template #tipContent>
    <span>
      {{'message.paginationFilterWarning' | translate}}
    </span>
    <button
      class="btn btn-outline-light btn-sm mt-2"
      (click)="forceCount()"
    >{{'label.forceCountAll' | translate}}
    </button>
  </ng-template>
</div>