import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotIncludesString } from './not-includes-string.directive';

@NgModule({
  declarations: [NotIncludesString],
  imports: [CommonModule],
  exports: [NotIncludesString],
})
export class NotIncludesStringModule {}
