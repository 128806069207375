import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

export function validatorNotRepeatedCharacter(
  control: AbstractControl
): ValidationErrors | null {
  const password = control.value;
  if (!password) {
    return null;
  }

  // Reject passwords with 4 or more consecutive repeated characters
  const consecutivePattern = /(.)\1{3,}/;
  if (consecutivePattern.test(password)) {
    return {
      repeatedCharacters: {
        actual: password,
      },
    };
  }

  return null;
}

@Directive({
  selector:
    '[not-repeated-character][formControlName],[not-repeated-character][formControl],[not-repeated-character][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotRepeatedCharacterDirective,
      multi: true,
    },
  ],
})
export class NotRepeatedCharacterDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return validatorNotRepeatedCharacter(control);
  }
}
