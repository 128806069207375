import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';

// Providers and services
import {
  LoginCustomizationResolver,
  WINDOW_PROVIDERS
} from './services';

const services = [
  LoginCustomizationResolver,
  WINDOW_PROVIDERS,
];

import { MainModule } from './pages/main/main.module';

@NgModule({
  imports: [
    MainModule
  ]
})

@Injectable()
export class LynxPardinuxModule {

  static forRoot(): ModuleWithProviders<LynxPardinuxModule> {
    return {
      ngModule: LynxPardinuxModule,
      providers: [
        ...services
      ]
    };
  }

}
