/**
 * Created by angel on 4/07/17.
 */
import { Serializable } from './serializable.class';

export class MyFile extends Serializable {

  filename: string;
  contentType: string;
  length: number;
  chunkSize: number;
  uploadDate: string;
  md5: string;

  public getResourceName(): string {
    return 'file';
  }
}
