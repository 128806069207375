import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from 'pod-ng-core';
import { flatMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordChangeGuard implements CanActivate {
  private session: SessionService;

  constructor(private router: Router, injector: Injector) {
    this.session = injector.get(SessionService);
  }

  canActivate(): Observable<boolean> {
    return this.session.getUserMe().pipe(
      flatMap(user => {
        if (user.isExpiredPassword) {
          this.router.navigate(['expired-password']);
          return of(false);
        }
        return of(true);
      })
    );
  }
}
