import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { URLFormatValidatorDirective } from './url-format.validator.directive';
import { URLReachableValidatorDirective } from './url-reachable.validator.directive';

@NgModule({
  declarations: [URLFormatValidatorDirective, URLReachableValidatorDirective],
  imports: [CommonModule],
  exports: [URLFormatValidatorDirective, URLReachableValidatorDirective],
})
export class URLValidatorDirectiveModule {}
