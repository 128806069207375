import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloaderComponent } from './preloader.component';
import { LoadingDotsModule } from 'pod-ng-core';

@NgModule({
  declarations: [
    PreloaderComponent
  ],
  imports: [
    CommonModule,
    LoadingDotsModule
  ],
  exports: [
    PreloaderComponent
  ],
  providers: [],
})
export class PreloaderModule {}
