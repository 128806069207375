<select
  *ngIf="!multiple"
  class="form-control custom-select"
  [ngClass]="size ? 'form-control-' + size : ''"
  [formControl]="control"
  (blur)="onBlur()">
  <option *ngIf="getPlaceholder()" [ngValue]="null">{{getPlaceholder()}}</option>
  <ng-content></ng-content>
  <ng-container *ngIf="items$">
    <option
      *ngFor="let item of (items$ | async)"
      [ngValue]="bindValue ? item[bindValue] : item">
      {{bindLabel ? item[bindLabel] : item}}
    </option>
  </ng-container>
</select>

<ng-select
  *ngIf="multiple"
  [formControl]="control"
  (blur)="onBlur()"
  [multiple]="multiple"
  [items]="items$ | async"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [closeOnSelect]="closeOnSelect"
  [placeholder]="getPlaceholder()"
  [dropdownPosition]="dropdownPosition"
  appendTo="body">
</ng-select>
