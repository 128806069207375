import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import IpSubnetCalculator from 'ip-subnet-calculator';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ip][formControlName],[ip][formControl],[ip][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IpValidatorDirective),
      multi: true,
    },
  ],
})
export class IpValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return control.value === undefined ||
      control.value === null ||
      IpSubnetCalculator.isIp(control.value)
      ? null
      : {
          ip: {
            actual: control.value,
          },
        };
  }
}
