import { Serializable } from './serializable.class';

export interface CDRRating {
  cost: {
    amount: number;
    currency: string;
  };
  dataUsed: number;
  product: {
    id: string;
    name: string;
    remainingBytes: number;
    remainingSms: number;
  };
}

export class CDRS extends Serializable {
  _id: string;
  iccid: string;
  msisdn: string;
  imei: string;
  accountId: string;
  limit: number;
  smsType: string;
  data: {
    id: string;
    type: string;
    startTime: string;
    endTime: string;
    billTime: string;
    servingNetwork: {
      mcc: string;
      mnc: string;
    };
    homeNetwork: {
      mcc: string;
      mnc: string;
    };
    profileMcc: string;
    profileMnc: string;
    bytes: number;
    roundedBytes: number;
    bytesInc: number;
    duration: number;
    carrierId?: string;
    carrier?: string;
    originatingAddress?: string;
    destinationAddress?: string;
  };
  ratings: Array<CDRRating>;

  // Own vars
  get cost(): number | string {
    const perMb = this.getPerMbRating();
    const rating = this.getLastRating();

    if (perMb && perMb.cost) {
      return perMb.cost && perMb.cost.amount;
    } else if (rating && rating.product) {
      if (rating.product.name && /_DataPool$/.test(rating.product.name)) {
        return 'inBundle';
      } else if (
        rating.product.name &&
        /_SharedPool$/.test(rating.product.name)
      ) {
        return 'inPool';
      } else if (
        rating.product.name &&
        /_PreActivation$/.test(rating.product.name)
      ) {
        return 'preactivation';
      } else if (rating.cost) {
        return rating.cost.amount;
      }
    }
  }

  get costCurrency(): string {
    const perMb = this.getPerMbRating();
    const rating = this.getLastRating();

    if (perMb && perMb.cost) {
      return perMb.cost.currency;
    } else if (rating && rating.cost && rating.cost.currency) {
      return rating.cost.currency;
    }
  }

  get remainingBytes(): number {
    const perMb = this.getPerMbRating();
    const rating = this.getLastRating();

    if (perMb && perMb.cost) {
      return 0;
    } else if (rating && rating.product) {
      return rating.product.remainingBytes;
    }
  }

  public getResourceName(): string {
    return 'cdr';
  }

  public getLastRating() {
    return this.ratings[this.ratings.length - 1];
  }

  public getPerMbRating() {
    return this.ratings.find(r => {
      return !!r.product && r.product.name && /_PerMb$/.test(r.product.name);
    });
  }
}
