import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IFaqSection } from './faq.component';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  public faqs: { [key: string]: IFaqSection };
  public faqs$: Observable<{ [key: string]: IFaqSection }>;

  constructor(private http: HttpClient) {}

  setupFaqs(lang: string) {
    this.faqs$ = this.http
      .get<{ [key: string]: IFaqSection }>(
        'assets/i18n/help-faqs-' + lang + '.json'
      )
      .pipe(
        map(data => {
          this.faqs = data;
          return data;
        })
      );
  }
}
