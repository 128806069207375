import { NgModule } from '@angular/core';

import { InputTextComponent } from './input-text.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    ReactiveFormsModule
  ],
  exports: [
    InputTextComponent
  ],
  declarations: [
    InputTextComponent
  ],
  providers: [],
  entryComponents: [
    InputTextComponent
  ]
})
export class InputTextModule { }
