import { Component, OnInit, Input } from '@angular/core';
import { LoadingDotsComponent } from 'pod-ng-core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent implements OnInit {
  @Input() size: LoadingDotsComponent['size'] = 'lg';

  constructor() {}

  ngOnInit(): void {}
}
