import { Serializable } from './serializable.class';

export enum RspType {
  M2M = 'M2M',
  CONSUMER = 'Consumer',
  IOT = 'IoT',
}

export class RspInstance extends Serializable {

  name: string;
  rspPlatform: string;
  apiClient: string;
  type: RspType;
  onstock: number;
  available: number;
  released: number;
  enabled: number;
  disabled: number;
  total: number;

  public getResourceName(): string {
    return 'rspinstances';
  }
}
