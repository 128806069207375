import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnDestroy,
  Injector,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControlDirective } from '@angular/forms';
import { Serializable } from '../../models';
import { ApiService } from '../../services/api.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessorConnector } from '../control-value-accesor-connector/control-value-accesor-connector';
import { DropdownPosition } from '@ng-select/ng-select';

@Component({
  selector: 'pod-select',
  templateUrl: 'select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  extends ControlValueAccessorConnector
  implements OnInit, OnDestroy
{
  @ViewChild(FormControlDirective)
  formControlDirective: FormControlDirective;

  @Input() size: string;
  @Input() multiple = false;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() placeholder: string;
  @Input() closeOnSelect = false;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() itemClass: new (...args: any[]) => Serializable;

  @Input()
  get items() {
    return this.items$.value;
  }
  set items(items: Array<any>) {
    this.items$.next(items);
  }

  private _filters: { [key: string]: any };
  @Input()
  get filters() {
    return this._filters;
  }
  set filters(f: { [key: string]: any }) {
    this._filters = f;
    this.getItems();
  }

  public items$ = new BehaviorSubject([]);

  private loading = false;

  constructor(
    injector: Injector,
    private apiService: ApiService,
    private translate: TranslateService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.itemClass) {
      this.getItems();
    }
  }

  ngOnDestroy() {
    this.items$.unsubscribe();
  }

  getPlaceholder() {
    return this.loading
      ? this.translate.instant('label.loading')
      : this.placeholder
      ? this.placeholder
      : '';
  }

  private getItems() {
    if (this.itemClass) {
      this.loading = true;
      this.apiService
        .getList(this.itemClass, this.filters)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(items => this.items$.next(items));
    }
  }
}
