import {
  Directive,
  forwardRef,
  ElementRef,
  Inject,
  Renderer2,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS,
} from '@angular/forms';
import { Sim } from '../../models';

@Directive({
  selector:
    '[megabytes][formControlName],[megabytes][formControl],[megabytes][ngModel],[kilobytes][formControlName],[kilobytes][formControl],[kilobytes][ngModel]',
  host: {
    '(input)': 'onInput($event.target.value)',
    '(blur)': 'onBlur()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BytesDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BytesDirective),
      multi: true,
    },
  ],
})
export class BytesDirective implements ControlValueAccessor, Validator {
  private innerValue: number;
  private decimalValue = 0;

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  private MAX = Number.MAX_SAFE_INTEGER;
  @Input() validateBytes: boolean = true;

  constructor(
    @Inject(ElementRef) private element: ElementRef,
    @Inject(Renderer2) private renderer: Renderer2
  ) {}

  validate(control: AbstractControl): ValidationErrors {
    if (!this.validateBytes) {
      return null;
    }

    if (!control.value || control.value < this.MAX) {
      return null;
    }

    const multiplier = this.getMultiplier();

    switch (multiplier) {
      case Sim.KILO_BYTES:
        return {
          kilobytes: {
            max: Math.floor(this.MAX / multiplier),
            actual: control.value,
          },
        };
      case Sim.MEGA_BYTES:
        return {
          megabytes: {
            max: Math.floor(this.MAX / multiplier),
            actual: control.value,
          },
        };
      default:
        return null;
    }
  }

  getMultiplier() {
    return this.element.nativeElement.hasAttribute('kilobytes')
      ? Sim.KILO_BYTES
      : this.element.nativeElement.hasAttribute('megabytes')
      ? Sim.MEGA_BYTES
      : 1;
  }

  onInput(value: any) {
    let result = value;
    if (result !== '') {
      result = result * this.getMultiplier();
      this.decimalValue = result % 1;
      result = Math.floor(result);
    }
    this.onChangeCallback(result);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any): void {
    // WORKAROUND: write value is always called a first time with null (https://github.com/angular/angular/issues/14988)
    if (!this.validateBytes) {
      this.element.nativeElement.value = value;
    } else if (this.innerValue === undefined && value === null) {
      this.innerValue = null;
      this.element.nativeElement.value = this.innerValue;
    } else {
      this.innerValue =
        value !== undefined && value !== null
          ? (value + this.decimalValue) / this.getMultiplier()
          : null;
      if (this.innerValue) {
        this.innerValue = Math.round(this.innerValue * 1000) / 1000;
      }
      this.element.nativeElement.value = this.innerValue;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(
      this.element.nativeElement,
      'disabled',
      isDisabled
    );
    // disable other components here
  }
}
