import { Serializable } from './serializable.class';
import { Sim, SimStatus, ESimProfileState, ISimTag } from './sim.class';

export enum ESimType {
  M2M = 'M2M',
  CONSUMER = 'Consumer',
}

export enum ESimModel {
  M2M_EMBEDDED_MFF2 = 'M2M Embedded MFF2',
  M2M_EMBEDDED_DFN6 = 'M2M Embedded DFN6',
  M2M_PLASTIC_TRIPLE_CUT = 'M2M Plastic Triple Cut',
}

export interface IESimSetup {
  accountId: string;
  accountName: string;
  eSimName: string;
  eSimGroupName: string;
  tags: Array<ISimTag>;
}

export interface IESimProfile {
  iccid: Sim['iccid'];
  status: SimStatus;
  profileState: ESimProfileState;
  enabled: boolean;
  bootstrap: boolean;
}

export interface SmsLessPendingOperations {
  action: string;
  status: string;
  expires: number;
}

export class ESim extends Serializable {
  eid: string;
  type: ESimType;
  model: ESimModel;
  profiles: IESimProfile[];
  setups: IESimSetup[];
  ownership: string[];
  ownerAccountId: string;
  ownerAccountName: string;
  callbackStatus: number;
  enabledProfile: Sim;
  rspProvider: string;

  // Own vars
  favorite: boolean;
  currentSetup: IESimSetup;
  bootstrapProfile: IESimProfile;
  smsLess: boolean;
  pendingOperation: Array<SmsLessPendingOperations>;

  public getResourceName(): string {
    return 'esims';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }

  public getIdKey() {
    return 'eid';
  }

  public init(json: any = {}) {
    super.init(json);

    this.profiles = this.profiles || [];

    // Set setup
    if (this.setups && this.setups[0]) {
      this.currentSetup = this.setups[0];
    }

    // Init enabled profile
    this.enabledProfile = new Sim(this.enabledProfile);

    // Set bootstrapProfile
    this.bootstrapProfile = this.profiles.find(p => p.bootstrap);
  }

  public isOperationLocked(): boolean {
    return this?.pendingOperation?.length > 0 || this.callbackStatus > 0;
  }
}
