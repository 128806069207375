import { Pipe, PipeTransform } from '@angular/core';
import { FileSizePipe } from '../file-size/file-size.pipe';

@Pipe({
  name: 'remaining'
})
export class RemainingPipe implements PipeTransform {

  constructor(
    private fileSizePipe: FileSizePipe
  ) { }

  transform(bytes: number|string, tipo: string): string {
    const precision = 4;

    if (typeof bytes === 'string') {
      if (isNaN(parseFloat(bytes))) {
        return null;
      }
      bytes = parseFloat(bytes);
    } else if (!isFinite(bytes)) {
      return null;
    }

    if (tipo === 'remaining') {
        return this.fileSizePipe.transform(bytes, precision);
    } else {
        if (bytes < 0) {
          return this.fileSizePipe.transform(bytes * (-1), precision);
        } else {
          return null;
        }
    }
  }

}
