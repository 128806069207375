import { Serializable } from './serializable.class';

export class SecurityAlert extends Serializable {

  id: number;
  iccid: string;
  msisdn: string;
  type: string;
  comments: string;
  time: string;
  timeStamp: string;
  sourceIP: string;
  sourcePort: string;
  sourceEthernet: string;
  destinationIP: string;
  destinationPort: string;
  destinationEthernet: string;
  protocol: string;
  description: string;
  alarmLink: string;
  alarmStatus: string;
  isThreat: boolean;
  archived: boolean;
  visible: boolean;
  recommendation: string;

  public getResourceName(): string {
    return 'alerts';
  }

  public getResourcePath(): string {
    return '/security/' + this.getResourceName();
  }

  public getIdKey(): string {
    return 'id';
  }
}
