import { Serializable } from './serializable.class';

export interface IZoneSchemeRegion {
  mcc: string;
  mnc: string;
  operator?: string;
  country?: string;
}

export interface IZoneSchemeZone {
  zoneId: string;
  zoneName: string;
  regions: Array<IZoneSchemeRegion>;
}

// TODO: add tests for this class
export class ZoneScheme extends Serializable {

  schemeId: string;
  schemeName: string;
  description: string;
  zones: Array<IZoneSchemeZone>;

  public getResourceName(): string {
    return 'zones-schemes';
  }
}
