import isReachable from 'is-reachable';

export function toURL(url: string): URL {
  let result;
  try {
    result = new URL(url);
  } catch (err) {
    result = null;
  }
  return result;
}

export function isValidURL(url: string): boolean {
  return !!toURL(url);
}

export function isReachableURL(url: string): boolean {
  return true; //TO BE FIXED IN BCNPOD-223
}
