import { Injectable } from '@angular/core';
import { compressSync, decompressSync, strFromU8, strToU8 } from 'fflate';

@Injectable({
  providedIn: 'root',
})
export class CompressionService {
  /**
   * Shortens an object by compressing it and into a Base64 string
   * @param obj Am object to be shortened
   * @returns Base64 string that represents the object
   */
  compressObject(obj: Record<string, any>): string {
    const buf = strToU8(JSON.stringify(obj), true);
    const compressed = compressSync(buf, {
      level: 9,
      mem: 9,
    });
    return btoa(strFromU8(compressed, true));
  }

  /**
   * Obtains an object from a Base64 string which was previously compressed
   * @param data Base64 string that contains an object
   * @returns Object resulting of the decompression
   */
  decompressObject(data: string): Record<string, any> {
    const compressed = strToU8(atob(data), true);
    const decompressed = decompressSync(compressed);
    return JSON.parse(strFromU8(decompressed, true));
  }
}
