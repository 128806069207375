import { Serializable } from './serializable.class';

export class Device extends Serializable {
  imei: string;
  tp: string;
  simIccid: string;
  name: string;
  group: string;
  status: DeviceStatus;
  deviceId: string;
  created: string;
  modified: string;

  public getResourceName(): string {
    return 'iot/device';
  }

  public getResourcePath(): string {
    return '/' + this.getResourceName();
  }

  public getExportPath(): string {
    return this.getBulkPath().substring(0, this.getBulkPath().length - 4);
  }

  public getIdKey(): string {
    return '_id';
  }
}

export enum DeviceStatus {
  DISCOVERED = 'discovered',
  LINKED = 'linked',
  ONBOARDED = 'onboarded',
  PROVISIONED = 'provisioned',
  UNLINKED = 'unlinked',
}
