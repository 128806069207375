import { Injectable, Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pod-confirm-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ 'message.pleaseConfirm' | translate }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
        id = "confirm-dialog-close"
      >
        <span aria-hidden="true" id = "confirm-dialog-close">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHtml]="message"></div>
    </div>
    <div class="modal-footer d-flex justify-content-around">
      <button
        type="button"
        class="btn {{ acceptBtnClass }}"
        (click)="activeModal.close()"
        id = "confirm-dialog-accept"
      >
        {{ acceptLabel | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="activeModal.dismiss()"
        id = "confirm-dialog-cancel"
      >
        {{ cancelLabel | translate }}
      </button>
    </div>
  `,
})
export class ConfirmDialogModal {
  @Input() message: string;
  @Input() acceptLabel: string;
  @Input() acceptBtnClass: string;
  @Input() cancelLabel: string;

  constructor(public activeModal: NgbActiveModal) {}
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private ngbModal: NgbModal) {}

  public confirm(
    message: string,
    acceptLabel: string = 'label.ok',
    acceptBtnClass: string = 'btn-primary',
    cancelLabel = 'label.cancel'
  ): Promise<any> {
    const modalRef = this.ngbModal.open(ConfirmDialogModal);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.acceptLabel = acceptLabel;
    modalRef.componentInstance.acceptBtnClass = acceptBtnClass;
    modalRef.componentInstance.cancelLabel = cancelLabel;

    return modalRef.result;
  }
}
