import { NgModule } from '@angular/core';

import { ButtonComponent, ButtonDirective } from './button.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    ButtonComponent,
    ButtonDirective
  ],
  declarations: [
    ButtonComponent,
    ButtonDirective
  ],
  providers: []
})
export class ButtonModule { }
