import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';

export const USE_CURRENCIES = new InjectionToken<Array<string>>(
  'USE_CURRENCIES'
);

@Pipe({
  name: 'podCurrency',
})
export class PodCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    @Inject(USE_CURRENCIES) private useCurrencies: Array<string>
  ) {}

  transform(
    value: any,
    currencyCode?: string,
    digits: string = '1.2-3',
    noComma = false
  ): string | null {
    let result = '';
    if (currencyCode && this.useCurrencies.includes(currencyCode)) {
      result = this.currencyPipe.transform(
        value || 0,
        currencyCode,
        'symbol',
        digits
      );
    } else {
      result = this.decimalPipe.transform(value || 0, digits);
    }

    if (noComma) {
      result = result.replace(/,/g, '');
    }

    return result;
  }
}
