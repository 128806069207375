import { Pipe, PipeTransform, Inject } from '@angular/core';
import { USE_API_HOST, USE_ACCOUNT_ID } from '../../services/api.service';
import { SessionService } from '../../services/session.service';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {
  constructor(
    private session: SessionService,
    @Inject(USE_API_HOST) protected useApiHost: string,
    @Inject(USE_ACCOUNT_ID) protected useAccountId: boolean = true
  ) {}

  transform(fileId: string, attachment = false, isPrivate = false): string {
    if (fileId) {
      let result: string =
        this.useApiHost + (isPrivate ? '/private/' : '/file/') + fileId + '?';
      if (this.useAccountId && this.session.account) {
        result += '&accountId=' + this.session.account.getId();
      }
      if (attachment) {
        result += '&attachment=true';
      }
      if (this.session.getToken()) {
        result += '&access_token=' + this.session.getToken();
      }
      return result;
    }

    return '';
  }
}
