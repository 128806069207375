import { Pipe, PipeTransform } from '@angular/core';

export enum FileSizeUnit {
  bytes,
  KB,
  MB,
  GB,
  TB,
  PB
}

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number|string, precision: number = 2, showUnits: boolean = true, minUnit: FileSizeUnit = FileSizeUnit.MB): string {

    if (typeof bytes === 'string') {
      if (isNaN(parseFloat(bytes))) {
        return '-';
      }
      bytes = parseFloat(bytes);
    } else if (!isFinite(bytes)) {
      return '-';
    }

    let number = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;

    if (!FileSizeUnit[number]) {
      return '-';
    }

    number = Math.max(number, minUnit);

    if (minUnit === 0 && bytes < 1024) {
      precision = 0;
    }

    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  (showUnits ? FileSizeUnit[number] : '');
  }

}
