import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotRepeatedCharacterDirective } from './not-repeated-character.directive';

@NgModule({
  declarations: [NotRepeatedCharacterDirective],
  imports: [CommonModule],
  exports: [NotRepeatedCharacterDirective],
})
export class NotRepeatedCharacterDirectiveModule {}
