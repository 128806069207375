import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { ReCaptchaModule } from 'angular-recaptcha3';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateCompiler,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import {
  Angulartics2Module,
  Angulartics2GoogleGlobalSiteTag,
} from 'angulartics2';
import { PodNgCoreModule, TokenInterceptor } from 'pod-ng-core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { LynxPardinuxModule } from '../module';
import { PreloaderModule } from 'src/components/preloader/preloader.module';
import { SocketService } from 'src/services/socket.service';
import { SocketInterceptor } from 'src/services/socket.interceptor';
import { GoogleAnalyticsService } from 'src/services/google-analytics.service';
import { SteeringListsModule } from 'ngx-slw';
import { MonacoEditorModule, MONACO_PATH } from '@materia-ui/ngx-monaco-editor';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  private static firstRegExp: RegExp = /([A-Z]+)/g;
  private static secondRegExp: RegExp = /([A-Z]+)/g;

  private missingTranslations: { [key: string]: boolean } = {};

  constructor() {
    if (!environment.production) {
      window['getMissingTranslations'] = () => {
        return Object.keys(this.missingTranslations);
      };
    }
  }

  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production && !this.missingTranslations[params.key]) {
      // tslint:disable-next-line:no-console
      console.info(
        'Translation for ' +
          params.key +
          ' not found. Type window.getMissingTranslations() in console for get a list of missing translations.'
      );
      this.missingTranslations[params.key] = true;
    }
    return params.key
      .split('.')
      .pop()
      .replace(MyMissingTranslationHandler.firstRegExp, ' $1')
      .replace(MyMissingTranslationHandler.secondRegExp, ' $1');
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    // TODO: config toastr https://github.com/scttcper/ngx-toastr#global-options
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: true,
    }),
    ReCaptchaModule.forRoot({
      language: 'en',
      normal: {
        sitekey: environment.recaptchaSiteKey,
      },
    }),
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    Angulartics2Module.forRoot({
      developerMode: !environment.googleAnalytics,
      gst: {
        trackingIds: environment.googleAnalytics
          ? [environment.googleAnalytics.id]
          : null,
      },
    }),
    MonacoEditorModule,
    SteeringListsModule.forRoot({
      googleMapsApiKey: environment.googleMapsApiKey,
    }),
    PodNgCoreModule.forRoot({
      apiHost: environment.apiHost,
      langs: environment.langs,
      currencies: environment.currencies,
      networksUrl: environment.networksUrl,
    }),
    LynxPardinuxModule.forRoot(),
    PreloaderModule,
  ],
  providers: [
    SocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SocketInterceptor,
      multi: true,
    },
    {
      provide: MONACO_PATH,
      useValue: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.32.1/min/vs',
    },
    Angulartics2GoogleGlobalSiteTag,
    Platform,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(googleAnalyticsService: GoogleAnalyticsService) {
    googleAnalyticsService.startTracking();
  }
}
