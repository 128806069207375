import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

export function validatorPasswordComplexity(c: AbstractControl) {
  if (
    !c.value ||
    (!!c.value.match(/[a-z]/) &&
      !!c.value.match(/[A-Z]/) &&
      !!c.value.match(/[0-9]/) &&
      !!c.value.match(
        /[ \!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~]/
      ))
  ) {
    return null;
  }
  return {
    passwordComplexity: {
      actual: c.value,
    },
  };
}
// TODO: add tests for this directive
@Directive({
  selector:
    '[password-complexity][formControlName],[password-complexity][formControl],[password-complexity][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComplexityValidatorDirective),
      multi: true,
    },
  ],
})
export class PasswordComplexityValidatorDirective implements Validator {
  validate(control: AbstractControl) {
    return validatorPasswordComplexity(control);
  }
}
