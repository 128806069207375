import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { TranslateModule } from '@ngx-translate/core';

import { USE_CURRENCIES } from './pipes/currency/pod-currency.pipe';
import { ConfirmDialogModal } from './services/dialog.service';
import {
  TokenInterceptor,
  USE_TOKEN_HEADER,
  USE_INTERCEPTOR_401_EXCLUSION,
} from './services/token.interceptor';
import { USE_API_HOST, USE_ACCOUNT_ID } from './services/api.service';
import { USE_LANGS } from './services/session.service';
import { USE_NETWORKS_URL } from './services/sims.service';
import { EncodeHttpParamsInterceptor } from './services/encodeHttpParams.interceptor';

const entryComponents = [ConfirmDialogModal];

export interface PodNgCoreModuleConfig {
  apiHost: string;
  langs: Array<string>;
  currencies: Array<string>;
  tokenHeader?: string;
  useAccountId?: boolean;
  networksUrl?: string;
  interceptor401Exclusion?: string;
}

@NgModule({
  declarations: [
    // Modals
    ...entryComponents,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgbModule,
    HttpClientModule,
    ToastrModule,
  ],
  entryComponents: [...entryComponents],
  exports: [
    // Modals
    ...entryComponents,
  ],
})
export class PodNgCoreModule {
  static forRoot(
    config: PodNgCoreModuleConfig
  ): ModuleWithProviders<PodNgCoreModule> {
    return {
      ngModule: PodNgCoreModule,
      providers: [
        { provide: USE_API_HOST, useValue: config.apiHost },
        { provide: USE_LANGS, useValue: config.langs },
        { provide: USE_TOKEN_HEADER, useValue: config.tokenHeader },
        { provide: USE_ACCOUNT_ID, useValue: config.useAccountId },
        { provide: USE_NETWORKS_URL, useValue: config.networksUrl },
        {
          provide: USE_INTERCEPTOR_401_EXCLUSION,
          useValue: config.interceptor401Exclusion,
        },
        { provide: USE_CURRENCIES, useValue: config.currencies },
      ],
    };
  }

  static forChild(): ModuleWithProviders<PodNgCoreModule> {
    return {
      ngModule: PodNgCoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EncodeHttpParamsInterceptor,
          multi: true,
        },
      ],
    };
  }
}
