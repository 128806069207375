import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export function notInclusedString(username: string): ValidatorFn {
  return (control: AbstractControl) => {
    const password = control.value;
    if (!username || !password) {
      return null;
    }

    const substitutions: { [key: string]: string } = {
      a: '4',
      e: '3',
      i: '1',
      o: '0',
      s: '5',
      t: '7',
    };

    const createSubstitutionPattern = (substring: string): RegExp => {
      let pattern = '';
      for (let char of substring) {
        if (substitutions[char.toLowerCase()]) {
          pattern += `[${char}${substitutions[char.toLowerCase()]}]`;
        } else {
          pattern += char;
        }
      }
      return new RegExp(pattern, 'i');
    };

    const pattern = createSubstitutionPattern(username);
    if (pattern.test(password)) {
      return {
        notPartOfUsername: {
          actual: password,
        },
      };
    }

    return null;
  };
}
@Directive({
  selector: '[notIncludesString]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotIncludesString,
      multi: true,
    },
  ],
})
export class NotIncludesString implements Validator {
  @Input('notIncludesString') value: string;

  validate(control: AbstractControl): ValidationErrors | null {
    return notInclusedString(this.value)(control);
  }
}
