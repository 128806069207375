import { Serializable } from './serializable.class';

export class Operation extends Serializable {

  iccid: string;
  userId: string;
  username: string;
  accountId: string;
  action: string;
  result: string;
  created: string;

  public getResourceName(): string {
    return 'events';
  }
}
