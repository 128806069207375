<div class="modal-header">
  <div class="modal-title">
    <h5>{{'label.termsAndConditions' | translate}}</h5>
    <p class="modal-title">
      {{'label.termsAndConditionsDescription' | translate}}
    </p>
  </div>
</div>
<div
  class="modal-body"
  style="overflow: auto !important;"
>
  <div *ngFor="let region of getRegions()">
    <a
      href="{{regionLinks[region]}}"
      target="_blank"
      (click)="onLinkClick()"
    >{{'label.termsAndConditionsLinkText' | translate:{x: (('label.' + region) | translate) } }}</a>
  </div>
</div>
<div class="modal-footer d-flex justify-content-around">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="onReject()"
    id="terms-and-condition-reject"
  >
    {{'label.rejectTAC' | translate}}
  </button>
  <button
    type="button"
    class="btn btn-tertiary"
    [disabled]="acceptDisabled"
    (click)="onAccept()"
    id="terms-and-condition-accept"
    [attr.title]="acceptDisabled ? ('label.termsAndConditionsRequired' | translate) : ''"
  >
    {{'label.acceptTAC' | translate}}
  </button>
</div>
