import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationClassDirective } from './validation-class.directive';

@NgModule({
  declarations: [
    ValidationClassDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ValidationClassDirective
  ]
})
export class ValidationClassDirectiveModule {}
