import { Serializable } from './serializable.class';

export class Stats extends Serializable {

  _id: string;
  cdrs: number;
  bytes: number;

  public getResourceName(): string {
    return 'stats';
  }

  public getResourcePath(): string {
    return '/cdr/' + this.getResourceName();
  }
}
