import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule, PodNgCoreModule } from 'pod-ng-core';
import { TermsAndConditionsModal } from 'src/components/modals/terms-and-conditions/terms-and-conditions.modal';
import { MainComponent } from './main.component';
import { StartComponent } from './start.component';

@NgModule({
  declarations: [MainComponent, StartComponent, TermsAndConditionsModal],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbTypeaheadModule,
    NgbCollapseModule,
    NgbDropdownModule,
    TranslateModule.forChild(),
    PodNgCoreModule.forChild(),
    PipesModule,
  ],
  exports: [MainComponent, StartComponent],
  providers: [],
})
export class MainModule {}
