/**
 * Created by angel on 26/06/17.
 */
import moment from 'moment';
import { I2faType } from '../services';
import { Serializable } from './serializable.class';

// export type UserFavoriteType = 'accounts' | 'assets' | 'products' | 'users' | 'billings' | 'summaries';

export enum UserFavoriteEnum {
  'accounts',
  'assets',
  'esims',
  'imsis',
  'products',
  'users',
  'billings',
  'summaries',
  'additionalProducts',
  'allDevice',
  'ztp-settings',
  'device',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type UserFavoriteType = keyof typeof UserFavoriteEnum;

export interface UserTwoFaStrategy {
  value: string;
}

export class User extends Serializable {
  public static STATUS_ARRAY: Array<any> = [
    {
      value: UserStatus.ACTIVE,
      label: 'status.active',
    },
    {
      value: UserStatus.INACTIVE,
      label: 'status.inactive',
    },
  ];

  username: string;
  email: string;
  password: string;
  modified: string;
  created: string;
  setup: any;
  permissions: Array<{
    _id: string;
    accountId: string;
    roles: Array<string>;
  }>;
  favorites: { [type: string]: Array<string> };
  status: UserStatus;
  lastLogin: any;
  profile: {
    language: string;
    timezone: string;
    picture: string;
  };
  twoFaStrategy?: Partial<Record<I2faType, UserTwoFaStrategy>>;

  // Own vars
  favoritesMap: { [type: string]: { [id: string]: boolean } };
  lastPasswordChange: Date;
  disabled2Fa: boolean;

  public init(json: any = {}) {
    super.init(json);

    this.setFavoritesMap();
  }

  public getResourceName(): string {
    return 'users';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }

  private setFavoritesMap() {
    if (!this.favorites) {
      this.favorites = {};
    }

    this.favoritesMap = {};

    // Getting valid keys from enum
    Object.keys(UserFavoriteEnum)
      .map(key => UserFavoriteEnum[key])
      .filter(value => typeof value === 'string')
      .forEach(key => {
        this.favoritesMap[key] = {};
        (this.favorites[key] || []).forEach(id => {
          this.favoritesMap[key][id] = true;
        });
      });
  }

  get isExpiredPassword(): boolean {
    if (!this.lastPasswordChange) {
      return true;
    }

    const diff = moment(this.lastPasswordChange)
      .add(90, 'days')
      .diff(moment(), 'days');

    if (diff < 0) {
      return true;
    }
  }
}
