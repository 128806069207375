import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  NgbPaginationModule,
  NgbDropdownModule,
  NgbTooltipModule ,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';

import { PipesModule } from '../../pipes/pipes.module';
import { LoadingDotsModule } from '../loading-dots/loading-dots.module';
import { CustomizableTableComponent } from './customizable-table.component';
import { CustomizableTableCustomizeModal } from './customizable-table-customize.modal';
import { ExportModal } from './export-modal/export.modal';

@NgModule({
  declarations: [
    CustomizableTableComponent,
    CustomizableTableCustomizeModal,
    ExportModal,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    LoadingDotsModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbTooltipModule ,
    DragulaModule,
    PipesModule,
  ],
  exports: [CustomizableTableComponent],
  providers: [],
  entryComponents: [
    CustomizableTableComponent,
    CustomizableTableCustomizeModal,
    ExportModal,
  ],
})
export class CustomizableTableModule {}
