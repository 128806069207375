// https://github.com/angulartics/angulartics2/issues/315

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { SessionService } from 'pod-ng-core';

import { environment } from 'src/environments/environment';

declare var gtag: any;
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    @Inject(DOCUMENT) document: any,
    private session: SessionService
  ) {
    if (environment.googleAnalytics) {
      this.loadGoogleAnalytics();
    }
  }

  private loadGoogleAnalytics() {
    // injecting GA main script asynchronously
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics.id}`;
    script.async = true;
    document.body.appendChild(script);

    // preparing GA API to be usable even before the async script is loaded
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    gtag('js', new Date());

    // tracking current url at app bootstrap
    gtag('config', environment.googleAnalytics.id);
  }

  public startTracking() {
    if (environment.googleAnalytics) {
      this.angulartics2GoogleGlobalSiteTag.startTracking();
    }
  }

  public setUsername(username: string) {
    this.angulartics2GoogleGlobalSiteTag.setUsername(username);
  }

  public setUserProperties(properties) {
    this.angulartics2GoogleGlobalSiteTag.setUserProperties(properties);
  }

  public eventTrack(
    action: string,
    category: string,
    label?: string,
    data?: any
  ) {
    // this.angulartics2GoogleGlobalSiteTag.eventTrack(action, properties)
    this.angulartics2GoogleGlobalSiteTag.eventTrack(action, {
      category,
      label,
      gstCustom: {
        dimension1:
          (this.session.account && this.session.account.getId()) || undefined,
        dimension2:
          (this.session.user && this.session.user.getId()) || undefined,
        dimension3:
          (this.session.user &&
            this.session.user.permissions &&
            this.session.user.permissions[0] &&
            this.session.user.permissions[0].roles) ||
          undefined,
        ...data,
      },
    });
  }
}
