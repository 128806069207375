import { Serializable } from './serializable.class';

export enum ImsiStateRoot {
  ROOT = 'root',
}

export enum ImsiStateVirtual {
  ONSTOCK = 'onstock',
  DOWNLOADING = 'downloading',
  INSTALLED = 'installed',
  DISABLING = 'disabling',
  DISABLED = 'disabled',
  DELETING = 'deleting',
}

export const ImsiState = {
  ...ImsiStateRoot,
  ...ImsiStateVirtual,
};
export type ImsiState = ImsiStateRoot | ImsiStateVirtual;

export interface ImsiSetup {
  accountId: string;
  imsiName?: string;
  imsiGroupName?: string;
}

export class Imsi extends Serializable {
  imsi: string;
  network: string;
  iccid: string;
  msisdn: string;
  state: ImsiState;
  setups: ImsiSetup[];
  ownership: string[];
  ownerAccountId: string;
  ownerAccountName: string;
  slot?: number;

  // Own vars
  favorite: boolean;
  currentSetup: ImsiSetup; // setups[0]

  public getResourceName(): string {
    return 'imsis';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }

  public getIdKey() {
    return 'imsi';
  }

  public init(json: any = {}) {
    super.init(json);

    this.currentSetup = null;
    if (this.setups && this.setups[0]) {
      this.currentSetup = this.setups[0];
    }
  }
}
