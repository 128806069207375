import { Serializable } from './serializable.class';
import { Cycle } from './bundle-report.iface';

export enum DiscountConcept {
  NEXT_CYCLE_LINE_RENTAL = 'nextCycleLineRentalTotalCost',
  OVERUSE = 'overuseTotalCost',
  PERMB = 'perMbTotalCost',
  SMS = 'smsTotalCost',
  VOICE = 'voiceTotalCost',
}

export enum DiscountType {
  VOLUME = 'volume',
  TIER = 'tier',
}

export enum DiscountAmountType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export enum DiscountValidStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
}
export enum DiscountInvalidStatus {
  DELETED = 'deleted',
}

export type DiscountAllStatus = DiscountValidStatus & DiscountInvalidStatus;

export interface DiscountProduct {
  id: string;
  name?: string;
}

export interface DiscountRange {
  amount: number;
  amountType: DiscountAmountType;
  since: number;
}

export class Discount extends Serializable {
  public name: string;
  public accountId: string;
  public accountTransferId: string;
  public accountTransferName: string;
  public accountCurrency: string;
  public products: DiscountProduct[];
  public startCycle: Cycle;
  public numCycles?: number;
  public concept: DiscountConcept;
  public type: DiscountType;
  public ranges: DiscountRange[];
  public status: DiscountAllStatus;

  public static getCyclesDiff = (cycleA: Cycle, cycleB?: Cycle): number => {
    cycleB = cycleB || {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    };

    return (cycleA.year - cycleB.year) * 12 + cycleA.month - cycleB.month;
  };

  public getResourceName(): string {
    return 'discounts';
  }

  public getApplicableRange(cost: number): DiscountRange {
    return this.ranges.reduce<DiscountRange>((prev, range) => {
      if (cost >= range.since) {
        return range;
      }
      return prev;
    }, null);
  }
}

export interface DiscountHistory {
  cycle: Cycle;
  discountId: string;
  productId: string;
  productName: string;
  total: number;
  applied: number;
}
