import { Pipe, PipeTransform } from '@angular/core';
import { Sim } from '../../models';

@Pipe({
  name: 'fileSizeMb'
})
export class FileSizeMbPipe implements PipeTransform {

  transform(bytes: number|string, precision: number = 2): string {
    if (typeof bytes === 'string') {
      if (isNaN(parseFloat(bytes))) {
        return '-';
      }
      bytes = parseFloat(bytes);
    } else if (!isFinite(bytes)) {
      return '-';
    }

    return (bytes / Sim.MEGA_BYTES).toFixed(precision) + '';
  }

}
