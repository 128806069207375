import { Serializable } from './serializable.class';

export enum SummaryStatus {
  IN_PROCESS = 'in-process',
  COMPLETED = 'completed',
}

export interface ISummaryData {
  totalBytes: number;
  totalSessions: number;
}

export class Summary extends Serializable {
  id: string;
  name: string;
  accountId: string;
  createdAt: string;
  filter: {
    dateFrom: string;
    dateTo: string;
    accountIds: string[];
    iccids: string[];
    productIds: string[];
  };
  status: SummaryStatus;
  total: {
    totalAccounts: number;
    totalBytes: number;
    totalDays: number;
    totalLocations: number;
    totalSims: number;
    totalProducts: number;
  };
  data: {
    accounts: (ISummaryData & {
      account: string;
    })[];
    dates: (ISummaryData & {
      date: string;
    })[];
    iccids: (ISummaryData & {
      iccid: string;
      account: string;
      product: string;
    })[];
    locations: (ISummaryData & {
      location: string;
    })[];
    products: (ISummaryData & {
      product: string;
    })[];
  };

  public getIdKey() {
    return 'id';
  }

  public getResourceName(): string {
    return 'custom';
  }

  public getResourcePath(): string {
    return '/reports/' + this.getResourceName();
  }

  public isCompleted(): boolean {
    return this.status === SummaryStatus.COMPLETED;
  }
}
