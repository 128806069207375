import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordComplexityValidatorDirective } from './password-complexity-validator.directive';

@NgModule({
  declarations: [
    PasswordComplexityValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PasswordComplexityValidatorDirective
  ]
})
export class PasswordComplexityValidatorDirectiveModule {}
