import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

export function minArrayLengthValidator(minArrayLength: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return (control.value === undefined || control.value === null || control.value.length >= minArrayLength) ? null : {
      minArrayLength: {
        minArrayLength: minArrayLength,
        actual: control.value.length
      }
    };
  };
}

@Directive({
  selector: '[minArrayLength][formControlName],[minArrayLength][formControl],[minArrayLength][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinArrayLengthValidatorDirective),
    multi: true
  }]
})
export class MinArrayLengthValidatorDirective implements Validator {
  @Input() minArrayLength: number;

  validate(control: AbstractControl) {
    return minArrayLengthValidator(this.minArrayLength)(control);
  }
}
