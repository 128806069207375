import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinArrayLengthValidatorDirective } from './min-array-length-validator.directive';

@NgModule({
  declarations: [
    MinArrayLengthValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MinArrayLengthValidatorDirective
  ]
})
export class MinArrayLengthValidatorDirectiveModule {}
