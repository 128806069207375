import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketInterceptor implements HttpInterceptor {

  constructor(
    private socketService: SocketService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.apiHost) && this.socketService.socketId) {
      const headers = {};
      headers['x-socket-id'] = this.socketService.socketId;

      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request);
  }
}
