import { Serializable } from 'pod-ng-core';
import moment from 'moment-timezone';

export enum CampaignItemStatus {
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success',
  CANCELLED = 'cancelled',
}

export enum CampaignType {
  STEERING = 'steering',
  DOWNLOAD_PROFILE = 'download_profile',
  DISABLE_PROFILE = 'disable_profile',
  DELETE_PROFILE = 'delete_profile',
  AUDIT_ESIM = 'audit_esim',
  ADD_IMSI = 'add_imsi',
  LOCK_IMEI = 'lock_imei',
}

export enum CampaignStatus {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  RUNNING = 'running',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export abstract class CampaignItem extends Serializable {
  campaignId: string;
  iccid: string;
  eid?: string;
  imsi?: string;
  status: CampaignItemStatus;
  attempts: number;
  created: string;
  modified: string;
  error: {
    code: string;
    message: string;
    detail: string;
  };

  public getResourceName(): string {
    return 'campaignitems';
  }
}

// TODO: move to core?
export class Campaign extends Serializable {
  public static NOT_FINISHED_STATUS: Array<CampaignStatus> = [
    CampaignStatus.PENDING,
    CampaignStatus.SCHEDULED,
    CampaignStatus.RUNNING,
  ];

  public static FINISHED_STATUS: Array<CampaignStatus> = [
    CampaignStatus.CANCELLED,
    CampaignStatus.COMPLETED,
    CampaignStatus.EXPIRED,
  ];

  public static NOT_STARTED_STATUS: Array<CampaignStatus> = [
    CampaignStatus.PENDING,
    CampaignStatus.SCHEDULED,
  ];

  accountId: string;
  userId: string;
  name: string;
  type: CampaignType;
  status: CampaignStatus;
  created: string;
  modified: string;
  updateTime: string;
  scheduled: string;
  startTime: string;
  endTime: string;
  csvFile?: {
    _id: string;
    filename: string;
  }; // File id
  filters: Record<string, any>;
  process: Record<CampaignItemStatus | 'total', number>;

  public getResourceName(): string {
    return 'campaigns';
  }

  public hasBeenStarted(): boolean {
    return !Campaign.NOT_STARTED_STATUS.includes(this.status);
  }

  public hasBeenFinished(): boolean {
    return Campaign.FINISHED_STATUS.includes(this.status);
  }

  public isCancellable(): boolean {
    return false;
  }

  public canBeCancelled(): boolean {
    return false;
  }

  public getProcessingTime(now?: moment.MomentInput): {
    hours: number;
    minutes: number;
  } {
    const momentStart = moment(this.startTime);
    const momentEnd = moment(this.endTime || now || undefined);

    const hours = momentEnd.diff(momentStart, 'hours');
    const minutes = momentEnd
      .subtract(hours, 'hours')
      .diff(momentStart, 'minutes');

    return { hours, minutes };
  }

  public getClone() {
    // Use specializations for specific props
    const { type, name, filters, csvFile } = this;
    return {
      type,
      name: name + ' - cloned',
      filters,
      csvFile,
    };
  }

  public canBeLaunched() {
    // Use specializations for specific requirements
    return true;
  }

  public getCampaignItemClass() {
    const parent = this;

    return class MyCampaignItem extends CampaignItem {
      public getResourcePath() {
        return parent.getResourcePath() + '/' + parent.getId() + '/items';
      }
    };
  }
}

export interface ICampaignCompletionForecast {
  complete: number;
  total: number;
  minutes: number;
  errors: Record<string, string[]>;
}
