import moment from 'moment-timezone';

import { Serializable } from 'pod-ng-core';

export enum StatusComponentStatus {
  OPERATIONAL = '1',
  PERFORMANCE_ISSUES = '2',
  PARTIAL_OUTAGE = '3',
  MAJOR_OUTAGE = '4',
}

export class StatusComponent extends Serializable {

  id: string;
  name: string;
  description?: string;
  link?: string;
  status: StatusComponentStatus;
  statusName: string;
  tags: string[];
  created: string;
  modified: string;

  // Own vars
  statusLevel: number;

  getIdKey() {
    return 'id';
  }

  getResourceName(): string {
    return 'statusComponents';
  }

  getResourcePath(): string {
    return '/status/components'
  }

  public init(json: any = {}) {
    super.init(json);

    this.statusLevel = parseInt(this.status);
  }

}
