import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'podNumber',
})
export class PodDecimalPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, digits?: string, noComma = false): string | null {
    let result = this.decimalPipe.transform(value || 0, digits || undefined);

    if (noComma) {
      result = result.replace(/,/g, '');
    }

    return result;
  }
}
