import { IEnvironment } from './environment.iface';

export const environment: IEnvironment = {
  production: true,
  apiHost: 'https://api.iotsuite.gi-de.com/v3',
  billingReportsBaseUrl: 'https://api.iotsuite.gi-de.com/v3',
  pusherApiKey: 'e79663fb2291ac0ad04a',
  puhserApiCluster: 'eu',
  hmr: false,

  // First item in langs is the default lang
  // zh is chinese
  langs: ['en', 'es', 'zh-cn'],

  // First item in themes is the default theme
  themes: [
    {
      id: 'blue',
      name: 'Blue',
      file: 'blue.bundle.css',
    },
    {
      id: 'bluepink',
      name: 'Blue&Pink',
      file: 'bluepink.bundle.css',
    },
    {
      id: 'green',
      name: 'Green',
      file: 'green.bundle.css',
    },
    {
      id: 'purple',
      name: 'Purple',
      file: 'purple.bundle.css',
    },
    {
      id: 'lightblue',
      name: 'Light Blue',
      file: 'lightblue.bundle.css',
    },
    {
      id: 'darkblue',
      name: 'Dark Blue',
      file: 'darkblue.bundle.css',
    },
    {
      id: 'orange',
      name: 'Orange',
      file: 'orange.bundle.css',
    },
  ],

  currencies: ['USD', 'EUR', 'GBP', 'MXN', 'HKD', 'CNY', 'MYR', 'AUD', 'JPY'],

  customization: {
    phoneSupport: '00440123123123',
    supportEmail: 'support@demo.com',
    supportPageMessage:
      'Our support times are 9/5 7 days a week 365 days year.',
    loginPageMessage: 'A complete modular platform for IoT connectivity',
    pageTitle: '',
  },

  recaptchaSiteKey: '6LefIy0UAAAAAG5GZg3sd0mOZCLt7u_BiqtilA2H',

  googleMapsApiKey: 'AIzaSyC0Uysc2xKSbQKHuTaG77aNqObLyThucpo',

  networksUrl:
    'https://raw.githubusercontent.com/iotconnectivity/mcc-mnc-list/iotconnectivity/mcc-mnc-list.json',

  googleAnalytics: {
    id: 'G-WSTC822YR5',
    gtm: 'GTM-P5RZFS2Z',
  },

  ipaAllowedCarriers: [
    'UKJ',
    'ROTN',
    'NATM',
    'NASP',
    'ROPD',
    'ROPDT',
    'ROTEU',
    'ROTAM',
    'ROTAS',
    'ROTAU',
    'ROTGL',
    'ROTBR',
    'ROTBS',
    'NAUS',
    'ROPDB',
    'UKAT',
  ],
  maxFiltersInTable: 100000,
};
