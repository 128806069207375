import { Serializable } from './serializable.class';

export enum ZtpSettingStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export class ZtpSetting extends Serializable {
  status: ZtpSettingStatus;
  accountId: string;
  name: string;
  description: string;
  matchingRules: Record<string, any>;
  baseConfig: string;
  devicesApplied: number;
  created: string;
  modified: string;

  public getResourceName(): string {
    return 'iot/provisioning';
  }
}
