import { Component, OnInit, Input, forwardRef, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as moment_ from 'moment-timezone';
const moment = moment_;
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'pod-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimezoneSelectorComponent),
      multi: true
    }
  ]
})
export class TimezoneSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() required ? = false;

  // The internal data model
  private innerValue: any;

  public isDisabled: boolean;

  constructor(
    private elementRef: ElementRef
  ) {}

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  ngOnInit(): void {
    if (this.elementRef.nativeElement.getAttribute('required') === '') {
      this.required = true;
    }
  }

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Own methods

  public searchTimezone = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return term.length < 2 ?
          [] :
          moment.tz.names()
            .filter((item: string) => item.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10);
      })
    );
  }


}
