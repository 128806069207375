import { Injectable } from '@angular/core';
import { AUTH_TOKEN_STORAGE_KEY, SessionService } from 'pod-ng-core';
import Pusher, { Channel } from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private pusher: Pusher;
  private accountChannel: Channel;
  private channels: Record<string, Channel> = {};

  private _socketId: string;

  public get socketId() {
    return this._socketId;
  }

  public set socketId(id: string) {
    this._socketId = id;
  }

  constructor(private session: SessionService) {}

  public init() {
    this.pusher = new Pusher(environment.pusherApiKey, {
      cluster: environment.puhserApiCluster,
      authEndpoint: environment.apiHost + '/auth/pusher',
      auth: {
        headers: {
          'x-access-token': localStorage.getItem(AUTH_TOKEN_STORAGE_KEY),
        },
      },
    });

    this.pusher.connection.bind('connected', event => {
      this.socketId = event.socket_id;
    });

    this.accountChannel = this.pusher.subscribe(
      'private-' + this.session.account.getId()
    );
  }

  public onAccount(eventName: string, callback: Function) {
    if (!this.accountChannel) {
      return console.warn('No account channel found');
    }
    this.accountChannel.bind(eventName, callback);
  }

  public offAccount(eventName: string, callback?: Function) {
    if (!this.accountChannel) {
      return console.warn('No account channel found');
    }
    this.accountChannel.unbind(eventName, callback);
  }

  private subscribe(channelName: string) {
    if (!this.pusher) {
      throw 'Service not initialized';
    }

    this.channels[channelName] =
      this.channels[channelName] || this.pusher.subscribe(channelName);
  }

  public on(channelName: string, eventName: string, callback: Function) {
    if (!this.channels[channelName]) {
      this.subscribe(channelName);
    }
    this.channels[channelName].bind(eventName, callback);
  }

  public off(channelName: string, eventName: string, callback?: Function) {
    if (!this.channels[channelName]) {
      return;
    }
    this.channels[channelName].unbind(eventName, callback);
  }

  public destroy() {
    this.pusher.disconnect();
  }
}
