import { Serializable } from './serializable.class';
import { ZoneScheme } from './zone-scheme.class';

export interface IProductWizardSchema {
  properties: any;
  zoneScheme: Array<ZoneScheme>;
  required?: Array<string>;
}

export enum ProductCycle {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum RevenueCategory {
  PODCLASSIC = 'Pod Classic',
  KOLOMBUS = 'Kolumbus',
}

export enum ProductService {
  DATA = 'data',
  SMS = 'sms',
  VOICE = 'voice',
}

export enum ProductType {
  PER_MB = 'PerMb',
  AGGREGATE = 'Aggregate',
  SINGLE = 'Single',
  SHARED_DATA_POOL = 'SharedDataPool',
}

export enum ImsisType {
  SINGLE_IMSIS = 'singleimsis',
  MULTI_IMSIS = 'multiimsis',
  OTHERS = 'others',
}

export class Product extends Serializable {
  public static MAX_CONTRACT_LENGTH = 6;

  _id: string;
  accountId: string;
  accountTransferId: string;
  resellerProductId: string;
  resellerProductName: string;
  name: string;
  productType: ProductType;
  productTypeScheme: any;
  carriers: any;
  currency: string;
  cycle: ProductCycle;
  cycleUnits: string;
  initialSize: number;
  contractLength: number;
  cost: number;
  perMbCost: number;
  renewOnExpiry: boolean;
  hasContractLength: boolean;
  renewOnDepletion: boolean;
  allowOveruse: boolean;
  services: any;
  revenueCategory?: RevenueCategory;
  preactivation: {
    active: boolean;
    data: number;
    sms: number;
    voice: number;
  };
  productZoneSetup: {
    schemeId: string;
    schemeName: string;
    zones: Array<{
      zoneId: string;
      active: boolean;
      value: number;
    }>;
  };
  networks: {
    imsisType: ImsisType;
    multiimsis?: string[];
    singleimsis?: string;
  };

  // Own vars
  networkList: string[];

  public getResourceName(): string {
    return 'products';
  }

  public init(json: any = {}) {
    super.init(json);

    this.networkList = this.getNetworkList();
  }

  public getNetworkList(): string[] {
    if (!this.networks || !this.networks.imsisType) {
      return [];
    }

    switch (this.networks.imsisType) {
      case ImsisType.SINGLE_IMSIS:
        return [this.networks.singleimsis];
      case ImsisType.MULTI_IMSIS:
        return this.networks.multiimsis;
      default:
        return [];
    }
  }

  public getZones(): number {
    return (
      (this.productZoneSetup && this.productZoneSetup.zones) ||
      []
    ).filter(z => z.active).length;
  }
}
