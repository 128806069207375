import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDotsComponent } from './loading-dots.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoadingDotsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    LoadingDotsComponent
  ],
  providers: [],
  entryComponents: [
    LoadingDotsComponent
  ]
})
export class LoadingDotsModule {}
