export abstract class Serializable {
  protected _id: string;

  // Not override constructor, override init instead
  constructor(data: any = {}) {
    this.init(data);
  }

  public abstract getResourceName(): string;

  public getResourcePath() {
    return '/' + this.getResourceName();
  }

  public getIdKey() {
    return '_id';
  }

  public getId(): string {
    return this[this.getIdKey()];
  }

  public getSearchParams(): { [s: string]: string | number | boolean } {
    return {
      limit: 0,
    };
  }

  public init(data: any = {}) {
    Object.assign(this, data);
  }

  public getBulkPath(): string {
    return this.getResourcePath() + 'bulk';
  }

  public getExportPath(): string {
    return this.getResourcePath();
  }
}
