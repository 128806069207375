import { Serializable } from './serializable.class';
import { SimType } from './sim.class';

export enum EsimOperationResult {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum EsimOperationAction {
  DOWNLOAD = 'download',
  ENABLE = 'enable',
  DISABLE = 'disable',
  DELETE = 'delete',
  AUDIT = 'audit',
}

export class EsimOperation extends Serializable {
  eid?: string;
  iccid?: string;
  ownership: string[];
  ownerAccountId: string;
  ownerAccountName: string;
  action?: EsimOperationAction;
  result: EsimOperationResult;
  errorMessage?: string;
  provider?: string;
  created: string;

  public getResourceName(): string {
    return 'esim-operations';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }
}
