<div class="modal-header">
  <h5 class="modal-title">{{'label.exportDataCsv' | translate}}</h5>
</div>
<form (ngSubmit)="validate()">
  <div class="modal-body">
    <label>{{'label.exportRecordsSettings' | translate}}</label>
    <div class="form-group">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            value="all"
            name="allRecords"
            [(ngModel)]="model.recordsToExport"
            required
          >
          <span>{{'label.exportAllRecords' | translate}}
            <span
              *ngIf="total"
              class="export-indicator-modal-label"
            >
              ({{total}}{{'label.total' | translate}})
            </span>
          </span>
        </label>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check form-check-inline">
        <label
          class="form-check-label"
          [ngClass]="{'disabled': !filtered}"
        >
          <input
            class="form-check-input"
            type="radio"
            value="filtered"
            name="filteredRecords"
            [(ngModel)]="model.recordsToExport"
            [disabled]="!filtered"
            required
          >
          <span>{{'label.exportAllFilteredRecords' | translate}} <span
              class="export-indicator-modal-label">({{filtered}} {{'label.filtered' | translate}})</span></span>
        </label>
      </div>
    </div>
    <div *ngIf="selected" class="form-group">
      <div class="form-check form-check-inline">
        <label
          class="form-check-label"
        >
          <input
            class="form-check-input"
            type="radio"
            value="selected"
            name="selectedRecords"
            [(ngModel)]="model.recordsToExport"
            required
          >
          <span>{{'label.exportAllSelectedRecords' | translate}} <span
              class="export-indicator-modal-label">({{selected}} {{'label.selected' | translate}})</span></span>
        </label>
      </div>
    </div>

    <label>{{'label.exportColumnsSettings' | translate}}</label>
    <div class="form-group">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            value="all"
            name="allColumns"
            [(ngModel)]="model.columnsToExport"
            required
          >
          <span>{{'label.exportAllColumns' | translate}} <span
              class="export-indicator-modal-label">({{allColumns.length}}
              {{'label.columns' | translate}})</span></span>
        </label>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            value="visible"
            name="visibleColumns"
            [(ngModel)]="model.columnsToExport"
            required
          >
          <span>{{'label.exportOnlyVisible' | translate}} <span
              class="export-indicator-modal-label">({{selectedColumns.length}}
              {{'label.columns' | translate}})</span></span>
        </label>
      </div>
    </div>

    <div *ngIf="submitted">
      <p
        class="text-danger"
        *ngIf="model.recordsToExport && !model.columnsToExport"
      >
        {{'message.addColumnstoo' | translate}}
      </p>
      <p
        class="text-danger"
        *ngIf="!model.recordsToExport && model.columnsToExport"
      >
        {{'message.addRecordstoo' | translate}}
      </p>
    </div>

    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="activeModal.dismiss()"
        id="table-export-cancel"
      >{{'label.cancel' | translate}}</button>
      <button
        type="submit"
        class="btn btn-tertiary"
        [disabled]="!model.recordsToExport || !model.columnsToExport"
        id="table-export-exportcsv"
      >{{'label.exportCsv' | translate}}</button>
    </div>
  </div>
</form>
