<div
  *ngIf="locationSearch"
  [formGroup]="locationSearch"
>
  <fieldset class="form-group">
    <label>{{'label.country' | translate}}</label>
    <div class="form-control-inside-icon">
      <i
        class="inside-icon cursor-pointer material-symbols-outlined"
        (click)="locationSearch.get('mcc').reset()"
      >{{locationSearch.get('mcc').value ? 'close' : 'search'}}</i>
      <input
        type="text"
        class="form-control"
        placeholder="Start typing&hellip;"
        formControlName="mcc"
        [ngbTypeahead]="searchMcc"
        [resultFormatter]="formatterMcc"
        [inputFormatter]="formatterMcc"
        [editable]="false"
      >
    </div>
  </fieldset>
  <fieldset
    class="form-group"
    [disabled]="!locationSearch.get('mcc').value"
  >
    <label>{{'label.network' | translate}}</label>
    <div class="form-control-inside-icon">
      <i
        class="inside-icon cursor-pointer material-symbols-outlined"
        (click)="locationSearch.get('mnc').reset()"
      >{{locationSearch.get('mnc').value ? 'close' : 'search'}}</i>
      <input
        type="search"
        class="form-control"
        placeholder="Start typing&hellip;"
        formControlName="mnc"
        [ngbTypeahead]="searchMnc"
        [resultFormatter]="formatterMnc"
        [inputFormatter]="formatterMnc"
        [editable]="false"
      >
    </div>
  </fieldset>
</div>
