import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'pod-ng-core';
import { filter } from 'rxjs/operators';
import { FaqService } from 'src/components/faq/faq.service';
import { GoogleAnalyticsService } from 'src/services/google-analytics.service';
import { environment } from '../environments/environment';
import { WINDOW } from '../services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public firstNavigationEnd = true;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private doc: any,
    private router: Router,
    private translateService: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private faqService: FaqService,
    private session: SessionService
  ) {
    this.setGTagManagerHeader();
    this.setGTagManagerBody();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Go to top of page when a navigation ends successfully (not for the first time)
        if (!this.firstNavigationEnd) {
          this.window.scroll(0, 0);
        }

        // send event
        this.googleAnalyticsService.eventTrack(
          event.url,
          'navigation',
          this.session.account && this.session.account.getId()
        );

        this.firstNavigationEnd = false;
      });

    // Setup translation service
    const lang = this.translateService.getBrowserLang();
    const defaultLang = environment.langs[0];
    this.translateService.addLangs(environment.langs);
    this.translateService.setDefaultLang(defaultLang);
    this.translateService.use(lang);

    // Initialize FAQs
    this.translateService.onLangChange.subscribe(event => {
      this.faqService.setupFaqs(event.lang);
    });
  }

  private setGTagManagerHeader() {
    const scriptElement = this.doc.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push'
      + '({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)'
      + '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
      + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})'
      + '(window,document,\'script\',\'dataLayer\',\'' + environment.googleAnalytics.gtm + '\');';
    const head = this.doc.getElementsByTagName('head')[0];
    head.prepend(scriptElement);
  }

  private setGTagManagerBody() {
    const noScriptElement  = this.doc.createElement('noscript');
    const iframeElement  = this.doc.createElement('iframe');
    iframeElement .width = '0';
    iframeElement .height = '0'; 
    iframeElement .style = 'display:none;visibility:hidden';
    iframeElement .src = 'https://www.googletagmanager.com/ns.html?id=' + environment.googleAnalytics.gtm;
    noScriptElement .prepend(iframeElement );
    const body = this.doc.getElementsByTagName('body')[0];
    body.prepend(noScriptElement );
  }
}
