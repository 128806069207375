import { Serializable } from './serializable.class';

export enum NotificationResult {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export class Notification extends Serializable {
  iccid?: string;
  eid?: string;
  imsi?: string;
  type: string;
  created: string;
  read: boolean;
  limit?: number;
  notification?: string;
  result: NotificationResult;
  meta?: any;

  public getResourceName(): string {
    return 'notifications';
  }

  public getResourcePath(): string {
    return '/accounts/' + this.getResourceName();
  }
}
