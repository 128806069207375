import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'asyncFunction',
})
export class AsyncFuncPipe implements PipeTransform {
  transform(asyncFunc: (..._args) => Observable<any>, ...args: any): any {
    return asyncFunc(...args);
  }
}
