import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FileUrlPipe } from 'pod-ng-core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private title: Title, private fileUrlPipe: FileUrlPipe) {}

  public setThemeById(themeId: string) {
    if (themeId) {
      const theme = environment.themes.find(th => th.id === themeId);
      if (theme) {
        this.setThemeByFile(theme.file);
      }
    }
  }

  public setThemeByFile(filename: string) {
    const linkElement = document.getElementById('theme-selector');
    if (
      linkElement &&
      filename &&
      filename !== linkElement.getAttribute('href')
    ) {
      linkElement.setAttribute('href', filename);
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  public setFavicon(favicon: string) {
    const faviconEl = document.getElementById('icon-selector');
    if (faviconEl) {
      faviconEl.setAttribute('href', this.fileUrlPipe.transform(favicon));
    }
  }
}
