import { Serializable } from './serializable.class';
import { Cycle } from './bundle-report.iface';
import { RevenueCategory } from './product.class';

export enum AdditionalProductFrecuencyUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export interface AdditionalProductFrecuency {
  cycle: number;
  cycleUnit?: AdditionalProductFrecuencyUnit;
}

export enum AdditionalProductType {
  CUSTOM = 'custom',
  ESIM_M2M_SERVICES = 'esim-m2m-services',
  BOLT_ON = 'bolt-on',
  VPN_SETUP = 'vpn-setup',
  VPN_MAINTENANCE = 'vpn-maintenance',
  CUSTOM_APN_SET_UP = 'custom-apn-set-up',
  CUSTOM_APN_RECURRING = 'custom-apn-recurring',
  POD_VERIFY = 'pod-verify',
  POD_CONNECT = 'pod-connect',
  SPECIAL_NETWORK_PROFILE = 'special-network-profile',
  SUPPORT_HOURS = 'support-hours',
  ESIM_MANAGEMENT_PLATFORM = 'esim-management-platform',
  PLATFORM_AS_A_SERVICE = 'platform-as-a-service',
  DORMANT_FEES = 'dormant-fees',
  SIGNAL_CHECKER = 'signal-checker',
}

export enum AdditionalProductValidStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
}
export enum AdditionalProductInvalidStatus {
  DELETED = 'deleted',
}

export type AdditionalProductAllStatus = AdditionalProductValidStatus &
  AdditionalProductInvalidStatus;

export enum AdditionalProductCostType {
  VOLUME = 'volume',
  TIER = 'tier',
  STAIRSTEP = 'stairStep',
  TIERACCUMULATION = 'tierAccumulation',
}

export interface AdditionalProductCostSchemeCostRange {
  since: number;
  cost: Record<AdditionalProductTierVolumeCostKeys, number>;
}

export interface AdditionalProductCostSchemeDiscountRange {
  since: number;
  discount: number;
}

export enum AdditionalProductEsimM2mServiceCostKeys {
  DOWNLOAD = 'downloadCost',
  DISABLE = 'disableCost',
  ENABLE = 'enableCost',
  DELETE = 'deleteCost',
}

export interface AdditionalProductCostSchemeEsimM2m {
  costType: AdditionalProductCostType;
  costRanges: AdditionalProductCostSchemeCostRange[];
  discountRanges: AdditionalProductCostSchemeDiscountRange[];
}

export interface AdditionalProductCostSchemeBoltOn {
  cost: number;
  overuseReduction: number;
}

export interface AdditionalProductCostSchemeCustom {
  cost: number;
}

export enum AdditionalProductGdBillingCostKeys {
  DOWNLOAD = 'downloadCost',
}

export enum AdditionalProductGdBillingBillableItemType {
  RECURRING = 'recurring',
  USAGE = 'usage',
}

export type AdditionalProductTierVolumeCostSchemes =
  AdditionalProductCostSchemeEsimM2m;

export type AdditionalProductTierVolumeCostKeys =
  | AdditionalProductEsimM2mServiceCostKeys
  | AdditionalProductGdBillingCostKeys;

export interface AdditionalProductCostSchemeDormantFees {
  cost: number; // If null, use a price for each carriers, in carrierCost
  carrierCost: Record<string, number>;
  suspendedMonths: number;
  inactiveMonths: number;
}

export interface AdditionalProductCostScheme {
  [AdditionalProductType.CUSTOM]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.ESIM_M2M_SERVICES]?: AdditionalProductCostSchemeEsimM2m;
  [AdditionalProductType.BOLT_ON]?: AdditionalProductCostSchemeBoltOn;
  [AdditionalProductType.VPN_SETUP]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.VPN_MAINTENANCE]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.CUSTOM_APN_SET_UP]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.CUSTOM_APN_RECURRING]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.POD_VERIFY]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.POD_CONNECT]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.SPECIAL_NETWORK_PROFILE]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.SUPPORT_HOURS]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.ESIM_MANAGEMENT_PLATFORM]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.PLATFORM_AS_A_SERVICE]?: AdditionalProductCostSchemeCustom;
  [AdditionalProductType.DORMANT_FEES]?: AdditionalProductCostSchemeDormantFees;
}

export class AdditionalProduct extends Serializable {
  public _id: string;
  public name: string;
  public description: string;
  public accountId: string;
  public accountTransferId: string;
  public accountTransferName: string;
  public accountCurrency: string;
  public frecuency: AdditionalProductFrecuency;
  public contractLength: number;
  public firstPaymentCycle: Cycle;
  public type: AdditionalProductType;
  public costScheme: AdditionalProductCostScheme;
  public status: AdditionalProductAllStatus;
  public productId?: string;
  public lastPaymentCycle: Cycle;
  public created: string;
  public modified: string;
  public revenueCategory?: RevenueCategory;

  public static getFrecuencyCycles = (
    cycle: number,
    cycleUnit: AdditionalProductFrecuencyUnit
  ) => {
    let mult: number;
    switch (cycleUnit) {
      case AdditionalProductFrecuencyUnit.MONTH:
        mult = 1;
        break;
      case AdditionalProductFrecuencyUnit.YEAR:
        mult = 12;
        break;
      default:
        throw `${cycleUnit} is not a valid cycleUnit`;
    }

    return cycle * mult;
  };

  public static getCyclesDiff = (cycleA: Cycle, cycleB?: Cycle): number => {
    cycleB = cycleB || {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    };

    return (cycleA.year - cycleB.year) * 12 + cycleA.month - cycleB.month;
  };

  public getResourceName(): string {
    return 'additional-products';
  }
}

export interface AdditionalProductHistory {
  _id: string;
  accountId: string;
  additionalProductId: string;
  cycle: Cycle;
  quantity: number;
  unitAmount: number;
  totalCostWithoutDiscount: number;
  discountAmount: number;
  total: number;
  description: string;
}
