import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment-timezone';
const moment = moment_;
import { MomentInput } from 'moment-timezone';
import { SessionService } from '../../services/session.service';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  constructor(
    private session: SessionService
  ) {}

  transform(date: MomentInput, format: string = 'YYYY-MM-DD HH:mm:ss', timezone: boolean = true): string {
    if (date) {
      // Add Z if not at final and remove _
      if (typeof date === 'string') {
        date = date.replace(/_/g, ' ');
        if (!date.match(/Z$/)) {
          date += 'Z';
        }
      }

      const apiTz = this.session.getTimezone();

      const m = moment(date);
      if (m.isValid()) {
        let result = m.tz(apiTz).format(format);
        if (timezone) {
          result += ' (' + apiTz + ')';
        }
        return result;
      }
    }
    return '';
  }

}
