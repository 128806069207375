import { NgModule } from '@angular/core';

import { NgSelectModule } from '@ng-select/ng-select';
import { SelectComponent } from './select.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule.forChild()
  ],
  exports: [
    SelectComponent
  ],
  declarations: [
    SelectComponent
  ],
  providers: [],
  entryComponents: [
    SelectComponent
  ]
})
export class SelectModule { }
