import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

export interface IExportModalResult {
  recordsToExport: 'all' | 'filtered' | 'selected',
  columnsToExport: 'allColumns' | 'visible',
}

@Component({
  selector: 'pod-export-modal',
  templateUrl: './export.modal.html'
})
export class ExportModal implements OnInit {

  @Input() total = 0;
  @Input() filtered = 0;
  @Input() selected = 0;

  @Input() allColumns: Array<string> = [];
  @Input() selectedColumns: Array<string> = [];

  public model: IExportModalResult;
  public submitted = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.model = {
      recordsToExport: this.selected ? 'selected' : null,
      columnsToExport: this.selectedColumns.length ? 'visible' : null
    };
  }

  public validate() {
    this.submitted = true;
    if (this.model.columnsToExport && this.model.recordsToExport) {
      this.activeModal.close(this.model);
    }
  }

}
