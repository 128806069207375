import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Serializable } from '../../models';

import { ITableField } from './customizable-table.component';

@Component({
  selector: 'pod-customizable-table-customize-modal',
  templateUrl: './customizable-table-customize.modal.html',
  styles: [
    `
      .dragula-card {
        cursor: move;
      }
    `,
  ],
})
export class CustomizableTableCustomizeModal<
  T extends Serializable = Serializable
> implements OnInit
{
  @Input() fields: Record<string, ITableField<T>>;
  @Input() selectedFields: string[];
  @Input() defaultSelectedFields?: string[];

  public availables: string[];
  public selecteds: string[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.defaultSelectedFields) {
      this.defaultSelectedFields = this.defaultSelectedFields.filter(
        key => !!this.fields[key] && this.fields[key].permission !== false
      );
    }
    this.selecteds = [...this.selectedFields];
    this.availables = Object.keys(this.fields).filter(
      key =>
        this.fields[key].permission !== false && this.selecteds.indexOf(key) < 0
    );
  }

  public selectNone() {
    this.availables = [].concat(this.selecteds, this.availables);
    this.selecteds = [];
  }

  public selectAll() {
    this.selecteds = [].concat(this.selecteds, this.availables);
    this.availables = [];
  }

  public selectDefault() {
    if (this.defaultSelectedFields) {
      this.selecteds = [...this.defaultSelectedFields];
      this.availables = Object.keys(this.fields).filter(
        key =>
          this.fields[key].permission !== false &&
          this.selecteds.indexOf(key) < 0
      );
    }
  }
}
