import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'pod-ng-core';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions.modal.html',
  styles: ['.modal-body : { overflow: auto !important; height: 500px }'],
})
export class TermsAndConditionsModal implements OnInit {
  @Input() versionNumber = 'V3-1-5';
  @Input() region: string;
  versionDate = '11/12/2020';
  acceptDisabled = true;

  regionLinks = {
    Europe: 'https://www.gi-de.com/en/connectivity-terms-and-conditions-es',
    US: 'https://www.gi-de.com/en/connectivity-terms-and-conditions-us',
    UK: 'https://www.gi-de.com/en/connectivity-terms-and-conditions-uk',
    LATAM: 'https://www.gi-de.com/en/connectivity-terms-and-conditions-uk',
    APAC: 'https://www.gi-de.com/en/connectivity-terms-and-conditions-hk',
  };

  locationRegions = {
    US: 'US',
    ES: 'Europe',
    LATAM: 'LATAM',
    UK: 'UK',
    HK: 'APAC',
  };

  constructor(
    public activeModal: NgbActiveModal,
    private session: SessionService
  ) {}

  ngOnInit() {}

  public onAccept() {
    this.activeModal.close(this.versionNumber);
  }

  public onReject() {
    this.activeModal.close(false);
  }

  onLinkClick() {
    this.acceptDisabled = false;
  }

  getRegions() {
    const userAccount = this.session.account;
    const location = userAccount.meta?.location;
    return this.locationRegions[location]
      ? [this.locationRegions[location]]
      : Object.keys(this.regionLinks);
  }
}
