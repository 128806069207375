import { Directive, forwardRef, Attribute } from '@angular/core';
import {
  Validator,
  AbstractControl,
  NG_VALIDATORS,
  ValidatorFn,
} from '@angular/forms';

export function validateEqualValidator(
  validateEqual: string,
  isReverse: boolean
): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any } | null => {
    // self value
    const v = c.value;

    // control value
    const e = c.root.get(validateEqual);

    // value not equal
    if (e && v !== e.value && !isReverse) {
      return {
        validateEqual: true,
      };
    }

    // value equal and reverse
    if (e && v === e.value && isReverse) {
      delete e.errors['validateEqual'];
      if (!Object.keys(e.errors).length) {
        e.setErrors(null);
      }
    }

    // value not equal and reverse
    if (e && v !== e.value && isReverse) {
      e.setErrors({ validateEqual: true });
    }

    return null;
  };
}

@Directive({
  selector:
    '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidatorDirective),
      multi: true,
    },
  ],
})
export class EqualValidatorDirective implements Validator {
  constructor(
    @Attribute('validateEqual') public validateEqual: string,
    @Attribute('reverse') public reverse: string
  ) {}

  private get isReverse() {
    if (!this.reverse) {
      return false;
    }
    return this.reverse === 'true' ? true : false;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return validateEqualValidator(this.validateEqual, this.isReverse)(c);
  }
}
