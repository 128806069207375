import { Component, Input } from '@angular/core';

@Component({
  selector: 'pod-loading-dots',
  template: `
    <div>
      <span class="loading-dot loading-dot-1{{size ? (' loading-dot-' + size) : ''}}"></span>
      <span class="loading-dot loading-dot-2{{size ? (' loading-dot-' + size) : ''}}"></span>
      <span class="loading-dot loading-dot-3{{size ? (' loading-dot-' + size) : ''}}"></span>
    </div>
    <div class="text-center loading-text {{size ? (' loading-text-' + size) : ''}}">{{'label.loading' | translate}}</div>
  `
})
export class LoadingDotsComponent {

  @Input() size?: 'lg' | 'sm';

}
