import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../../services';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  constructor(private session: SessionService) { }

  transform(action: string): boolean {
    return !!this.session.hasPermission(action);
  }
}
