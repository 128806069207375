<div class="modal-header">
  <h4 class="modal-title">{{'label.customizeTableColumns' | translate}}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
    id="table-customize-close"
  >
    <span
      aria-hidden="true"
      id="table-customize-close"
    >&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-row justify-content-between mb-2">
    <button
      type="button"
      class="btn btn-link btn-sm"
      (click)="selectAll()"
      id="table-customize-select-all"
    >
      <span
        class="material-symbols-outlined"
        id="table-customize-select-all"
      >arrow_left</span>
      <span id="table-customize-select-all">{{'label.selectAll' | translate}}</span>
    </button>
    <button
      *ngIf="defaultSelectedFields"
      type="button"
      class="btn btn-link btn-sm"
      (click)="selectDefault()"
      id="table-customize-select-default"
    >
      <span
        class="material-symbols-outlined"
        id="table-customize-select-default"
      >refresh</span>
      <span id="table-customize-select-default">{{'label.backToDefault' | translate}}</span>
    </button>
    <button
      type="button"
      class="btn btn-link btn-sm"
      (click)="selectNone()"
      id="table-customize-select-none"
    >
      <span id="table-customize-select-none">{{'label.selectNone' | translate}}</span>
      <span
        class="material-symbols-outlined"
        id="table-customize-select-none"
      >arrow_right</span>
    </button>
  </div>
  <div class="row">
    <h5 class="col-6">{{'label.usedColumns' | translate}}</h5>
    <h5 class="col-6">{{'label.unusedColumns' | translate}}</h5>
  </div>
  <div class="row">
    <div
      class="col-6"
      [dragula]="'table-customize-bag'"
      [(dragulaModel)]="selecteds"
    >
      <div
        class="card dragula-card"
        *ngFor="let key of selecteds"
      >
        <div
          class="card-body py-2"
          [innerHtml]="fields[key].label | translate"
        ></div>
      </div>
    </div>
    <div
      class="col-6"
      [dragula]="'table-customize-bag'"
      [(dragulaModel)]="availables"
    >
      <div
        class="card dragula-card"
        *ngFor="let key of availables"
      >
        <div
          class="card-body py-2"
          [innerHtml]="fields[key].label | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-around">
  <button
    type="button"
    class="btn btn-tertiary"
    (click)="activeModal.close(selecteds)"
    id="table-customize-save"
  >{{'label.save' | translate}}</button>
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
    id="table-customize-cancel"
  >{{'label.cancel' | translate}}</button>
</div>
